<template>
  <div class="MonitorCheck">
    <div class="card">
      <div class="card-body">
        <div class="param-list">
          <div class="param">
            <div class="param-name">Checked from</div>
            <div class="param-value">
              <node-name :node="check.node" :inline="true" />
            </div>
          </div>
          <div class="param">
            <div class="param-name">Checked URL</div>
            <div class="param-value param-url">
              <div class="method">{{ check.request.method }}</div>
              <a :href="check.url"
                 class="url"
                 _target="blank">{{ check.url }}</a>
              <a :href="ipLink"
                 class="ip"
                 target="_blank">{{ check.ip }}</a>
            </div>
          </div>
          <div class="param">
            <div class="param-name">Status</div>
            <div class="param-value">
              <status-code :check="check"
                           :code="check.response.code"
                           :reason-phrase="check.response.reason" />
            </div>
          </div>
          <div class="param">
            <div class="param-name" :class="{ 'align-self-start': requestBody }">Request Body</div>
            <div class="param-value highlight" v-if="requestBody">
              {{ requestBody }}
            </div>
            <div class="param-value" v-if="!requestBody">No Body</div>
          </div>
          <div class="param">
            <div class="param-name" :class="{ 'align-self-start': requestHeaders.length > 0 }">Request Headers</div>
            <div class="param-value highlight" v-if="requestHeaders.length > 0">
              <div class="header"
                   v-for="(header, index) in requestHeaders"
                   :key="index">
                {{ header.name }}: {{ header.value }}
              </div>
            </div>
            <div class="param-value" v-else>No Headers</div>
          </div>
          <div class="param">
            <div class="param-name" :class="{ 'align-self-start': responseHeaders.length > 0 }">Response Headers</div>
            <div class="param-value highlight" v-if="responseHeaders.length > 0">
              <div class="header"
                   v-for="(header, index) in responseHeaders"
                   :key="index">
                {{ header.name }}: {{ header.value }}
              </div>
            </div>
            <div class="param-value" v-else>No Headers</div>
          </div>
          <div class="param">
            <div class="param-name">Response Time</div>
            <div class="param-value">
              <template v-if="check.response_time">
                {{ check.response_time | responseTime }} sec
              </template>
              <template v-else>-</template>
            </div>
          </div>
          <div class="param">
            <div class="param-name">Response Size</div>
            <div class="param-value">
              <template v-if="check.response.size">
                {{ check.response.size | responseSize }}
              </template>
              <template v-else>-</template>
            </div>
          </div>
          <div class="param">
            <div class="param-name">Executed at</div>
            <div class="param-value">{{ check.created_at | shortDate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NodeName from '@/components/Node/NodeName.vue'
// import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'
import StatusCode from '@/components/MonitorCheck/MonitorCheckStatus.vue'
import { getIpResolveUrl } from '@/services/utils.js'

export default {
  components: {
    NodeName,
    StatusCode
  },

  props: {
    check: {
      required: true,
      type: Object
    }
  },

  computed: {
    requestBody () {
      switch (this.check.request.body_type) {
        case 'raw': return this.check.request.body_raw
        case 'json': return this.check.request.body_json
        case 'form_params': return this.check.request.body_form_params
        default: return null
      }
    },

    requestHeaders () {
      return Array.isArray(this.check.request.headers) ? this.check.request.headers : []
    },

    responseHeaders () {
      if (Array.isArray(this.check.response.headers)) {
        return this.check.response.headers
      } else if (typeof this.check.response.headers === 'string') {
        try {
          return JSON.parse(this.check.response.headers)
        } catch (error) {
          return []
        }
      }
      return []
    },

    ipLink () {
      return getIpResolveUrl(this.check.ip)
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorCheck {
    .param {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        justify-content: center;
      }

      .param-name {
        flex: 0 0 200px;
        margin-right: 20px;
        font-size: 14px;
        line-height: 1;
        color: #999ca7;
        text-align: right;
        @media (max-width: 767px) {
          flex: 0 0 130px;
        }
      }

      .param-value {
        flex: 1 0;
        font-size: 15px;
        line-height: 1;
        @media (max-width: 767px) {
          flex: 174px 0;
        }
      }

      .param-url {
        display: flex;
        align-items: flex-end;

        .method {
          font-size: 14px;
          text-transform: uppercase;
        }

        .url {
          @include truncate;

          margin-left: 6px;
          max-width: 400px;
        }

        .ip {
          margin-left: 4px;
          margin-bottom: 1px;
          color: #999ca7;
          font-size: 12px;

          &:hover {
            color: map-get($colors, purple-1);
          }
        }
      }

      .highlight {
        background-color: #1c1824;
        padding: 7px 10px;
        border-radius: 3px;
        overflow: auto;
        font-family: monospace;
        font-size: 13px;
        max-width: 510px;
        color: #8bebb6;
        font-weight: 400;

        .header {
          padding: 4px;
        }
      }
    }
  }
</style>
