<template>
  <div class="MonitorCheckStatus"
       ref="monitorCheckStatus">
    <div class="badge" :class="checkClasses">
      {{ status }}
    </div>

    <hint-failed-check class="ml-2" v-if="showFailedCheckHint" />
  </div>
</template>

<script>
import tippy from 'tippy.js'
import HintFailedCheck from '@/components/Hints/HintFailedCheck.vue'

export default {
  components: {
    HintFailedCheck
  },

  props: {
    check: {
      required: true,
      type: Object
    }
  },

  mounted () {
    if (this.check.is_failed && this.check.fail_reason) {
      tippy(this.$refs.monitorCheckStatus, {
        content: this.check.fail_reason,
        interactive: true
      })
    }
  },

  computed: {
    checkClasses () {
      // Executed but failed, no response received
      if (this.check.is_failed) {
        return 'danger'
      }

      // Executed but not successful, did not meet some conditions
      if (!this.check.is_successful) {
        return 'danger'
      }

      // Executed and met user conditions
      if (this.check.is_successful) {
        return 'success'
      }

      return 'warning'
    },

    status () {
      if (this.check.request.method.length === 0) {
        switch (this.check.request.monitor_request_type) {
          case 'tcp':
            if (this.check.is_successful) {
              return 'TCP - Connection Successful'
            } else {
              return 'TCP - Connection Failed'
            }
          case 'icmp':
            if (this.check.is_successful) {
              return 'ICMP - Host Reachable'
            } else {
              return 'ICMP - Host Unreachable'
            }
          default:
            if (this.protocol && this.code) {
              return 'HTTP/' + this.protocol + ' ' + this.code + ' ' + this.reason
            } else {
              return 'HTTP/1.1 408 Request Timeout'
            }
        }
      } else {
        if (this.protocol && this.code) {
          return 'HTTP/' + this.protocol + ' ' + this.code + ' ' + this.reason
        } else {
          return 'HTTP/1.1 408 Request Timeout'
        }
      }
    },

    protocol () {
      return this.check.response.protocol
    },

    code () {
      return this.check.response.code
    },

    reason () {
      return this.check.response.reason
    },

    showFailedCheckHint () {
      return (this.code >= 200 && this.code < 300) && !this.check.is_successful
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorCheckStatus {
    display: inline-flex;
    align-items: center;

    .badge {
      display: inline-flex;
      padding: 6px;
      border: 1px solid transparent;
      border-radius: 4px;
      font-size: 14px;

      &.success {
        @include badge-success;
      }

      &.warning {
        @include badge-warning;
      }

      &.danger {
        @include badge-danger;
      }
    }
  }
</style>
